var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper h-full w-full relative"},[(_vm.activeIdx > -1)?_vm._l((_vm.preparedPreferences),function(item,idx){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(idx <= _vm.activeIdx),expression:"idx <= activeIdx"}],class:[
        'preferenceWrapper absolute',
        _vm.slidingDirection,
        _vm.activeIdx === idx && _vm.sliding ? 'sliding' : '',
      ]},[_c('div',{staticClass:"p-2 h-full"},[_c('div',{staticClass:"preference h-full"},[_c('img',{staticClass:"rounded-lg baseImage",attrs:{"src":item.image}}),_c('div',{class:{
              'infoWrapper rounded-lg': true,
              textOverImage: _vm.textOverImage,
              open: _vm.showMoreOpen,
            }},[_c('div',{staticClass:"info relative"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.description)?_c('div',{class:{ description: true, open: _vm.showMoreOpen }},[_vm._v(" "+_vm._s(item.description)+" ")]):_vm._e(),(item.tags && item.tags.length > 0)?_c('div',{staticClass:"flex tagList mt-3"},_vm._l((item.tags),function(tag){return _c('tag',[_vm._v(_vm._s(tag))])}),1):_vm._e(),(item.description)?_c('button',{staticClass:"showMoreButton",on:{"click":_vm.toggleShowMore}},[_vm._v(" "+_vm._s(_vm.showMoreOpen ? "Show less" : "Show more")+" ")]):_vm._e()])])])]),_c('div',{staticClass:"actionButtons flex justify-center"},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Dislike'),expression:"'Dislike'"}],staticClass:"actionButton text-white bg-rose-600 color-white p-2",on:{"click":function($event){return _vm.goToNext('left')}}},[_c('img',{attrs:{"src":require("../assets/icons/cross.svg"),"alt":"Logo"}})]),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Super like'),expression:"'Super like'"}],staticClass:"actionButton small text-white bg-blue-700 color-white p-2",on:{"click":function($event){return _vm.goToNext('top')}}},[_c('img',{attrs:{"src":require("../assets/icons/star.svg"),"alt":"Logo"}})]),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Like'),expression:"'Like'"}],staticClass:"actionButton text-white bg-green-600 p-2",on:{"click":_vm.goToNext}},[_c('img',{attrs:{"src":require("../assets/icons/check.svg"),"alt":"Logo"}})])])])}):_c('div',{staticClass:"noPreferences"},[_c('p',[_vm._v("No more preferences")]),_c('button',{staticClass:"px-2 py-2 bg-emerald-400 rounded-md"},[_vm._v("Continue")])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }